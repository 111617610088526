import "./PlaceholderPage.css"
import logo from "../../img/GAM3R Square Logo.png"
import yt from "../../img/yt.png"

const PlaceholderPage = ({ placeholderMsg }) => {
  return (
    <div className="content">
      <img src={logo} />
      <h1 style={{ color: "white" }}>{placeholderMsg}</h1>
      <a href="https://www.youtube.com/c/GAM3RZA">
        <img src={yt} style={{ height: "120px" }} />
      </a>
    </div>
  )
}
export default PlaceholderPage