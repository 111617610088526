import PlaceholderPage from "./pages/placeholderpage/PlaceholderPage"

function App() {

  return (
    <>
      <PlaceholderPage placeholderMsg={"Follow the link below and find me on YouTube!"} />
    </>
  )
}

export default App
